<template>
  <div>
    <div class="header">
      <!--<van-nav-bar-->
      <!--  :title="orderinfo.type + ':' + tousername"-->
      <!--  left-arrow-->
      <!--  @click-left="$router.go(-1)"-->
      <!--/>-->
    </div>
    <div class="maincontent" ref="container">
      <!-- 订单信息 -->
      <div class="orderinfo" @click="todetail">
        <div class="info-top">
          <div span="12" style="text-align:left">交易总额</div>
          <div span="12" style="text-align:right">
            <!--<span v-if="parseInt(orderinfo.times) > 0">-->
            <!--  <van-icon name="clock"/><van-count-down-->
            <!--    :time="parseInt(orderinfo.times * 1000)"-->
            <!--    format="mm:ss"-->
            <!--/></span>-->
          </div>
        </div>
        <div class="info-bottom"
          ><div span="12" style="text-align:left">￥</div>
          <div span="12" style="text-align:right">
            <span v-if="orderinfo.status === '-1'">已取消</span>
            <span v-if="orderinfo.status === '0'">待付款</span>
            <span v-if="orderinfo.status === '1'">已付款</span>
            <span v-if="orderinfo.status === '2'">已完成</span>
          </div>
        </div>
      </div>
      <!-- 聊天记录 -->
      <div class="chatlist">
        <span v-show="isLoading" style="color:#2C90FF">加载中...</span>
        <div v-for="item in list" :key="item.id">
          <div v-if="item.type === 'order'" class="chat-order">
            <span class="order-time">{{ item.createtime | dateformat }}</span>
            <span class="order-msg">{{ item.content }}</span>
          </div>
          <div
            v-else
            class="chatinfo"
            :class="[item.userid === userid ? 'right' : 'left']"
          >
            <div
              v-if="item.userid === userid"
              style="display:flex;justify-content: flex-end;"
            >
              <span class="chatcontent" v-if="item.type === 'image'">
                <img
                  max-width="120px"
                  :src="item.content"
                  @click.prevent="previewimg(item.content)"
                />
              </span>
              <span class="chatcontent" v-else v-html="item.content"></span>
              <div
                style="width:32px;height:32px;background:#004ea3;color:#fff;border-radius:50%;line-height:32px;font-weight:600;font-size:1rem"
              >
                {{ username.substr(0, 1) }}
              </div>
            </div>
            <div v-else style="display:flex;justify-content: flex-start;">
              <div
                style="width:32px;height:32px;background:#000;color:#fff;border-radius:50%;line-height:32px;font-weight:600;font-size:1rem"
              >
                {{ tousername.substr(0, 1) }}
              </div>
              <span class="chatcontent" v-if="item.type === 'image'">
                <img
                  max-width="120px"
                  :src="item.content"
                  @click.prevent="previewimg(item.content)"
                />
              </span>
              <span class="chatcontent" v-else v-html="item.content"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="sendbox">
      <div class="van-hairline--top"></div>
      <input
        v-model="message"
        rows="1"
        autosize
        type="textarea"
        placeholder="在此输入你的对话"
      />
      <!--<div class="avatar-uploader">-->
      <!--  <div tabindex="0" class="el-upload el-upload--text">-->
      <!--    <i class="el-icon-plus avatar-uploader-icon"></i>-->
      <!--    <input type="file" name="file" class="el-upload__input">-->
      <!--  </div>-->
      <!--</div>-->
      <button class="send-btn" type="primary" @click="sendtext"
        >发 送</button
      >
    </div>
    <audio controls ref="notify" style="display:none">
      <source src="msg.mp3" />
    </audio>
  </div>
</template>
<script>
// import { ImagePreview } from 'vant'
export default {
  data() {
    return {
      scrollHeight: 0,
      orderinfo: {},
      userid: '',
      touid: '',
      tousername: '',
      username: '',
      websocket: null,
      message: '',
      isLoading: true,
      list: [],
      page: 1,
      count: 0,
      status_timer: null
    }
  },
  mounted() {
    this.getorderinfo()
    if (this.status_timer === null) {
      this.status_timer = setInterval(() => {
        this.getorderstatus()
      }, 1000)
    }
    this.getlist()
    const container = this.$refs.container
    setTimeout(() => {
      this.scrollHeight = container.scrollHeight
      container.scrollTo(0, this.scrollHeight)
    }, 1000)
    container.addEventListener('scroll', e => {
      if (
        e.target.scrollTop === 0 &&
        this.page <= 20 &&
        this.count > this.page * 20
      ) {
        this.isLoading = true
        setTimeout(() => {
          this.page++
          this.getlist()
          setTimeout(() => {
            container.scrollTo(0, e.target.scrollHeight - this.scrollHeight)
          }, 20)
        }, 1000)
        this.scrollHeight = container.scrollHeight
      }
    })
  },
  methods: {
    // 获取订单信息
    async getorderinfo() {
      const { data } = await this.$http.get(
        '/admin/user/orderinfo?id=' + this.$route.params.id
      )
      if (data) {
        if (data.code === 200) {
          this.orderinfo = data.data.orderinfo
          this.userid = data.data.userid
          this.touid = data.data.touid
          this.username = data.data.username
          this.tousername = data.data.tousername
          if (this.websocket === null) {
            // 请求数据后再初始化ws
            this.initwebsocket()
          }
        }
      }
    },
    // 获取订单状态
    async getorderstatus() {
      const { data } = await this.$http.get(
        '/admin/user/orderstatus?id=' + this.$route.params.id
      )
      if (data) {
        if (data.code === 200) {
          if (this.orderinfo.status !== data.data) {
            this.orderinfo.status = data.data
            // this.list.push({
            //   userid: this.userid,
            //   type: 'order',
            //   content: '买家已标记付款完成。请及时收款。',
            //   createtime: Math.round(new Date().getTime() / 1000).toString()
            // })
          }
        }
      }
    },
    // 订单详情页
    todetail() {
      // console.log(this.orderinfo.id)
      if (this.orderinfo.type === '卖家') {
        this.$router.push(
          '/trade/shopping/trading/buy_detail/' + this.orderinfo.id
        )
      } else {
        this.$router.push(
          '/trade/shopping/trading/sell_detail/' + this.orderinfo.id
        )
      }
    },
    // 获取历史记录
    async getlist() {
      const { data } = await this.$http.get(
        '/admin/user/chatinfo?id=' + this.$route.params.id + '&page=' + this.page
      )
      console.log(data)
      if (data) {
        if (data.code === 200) {
          this.count = +data.data.count
          data.data.list.forEach(item => {
            this.list.unshift(item)
          })
        }
      }
      this.isLoading = false
    },
    // 滚动到底部
    goto() {
      this.$nextTick(() => {
        const dom = document.querySelector('.maincontent')
        dom.scrollTop += dom.scrollHeight
      })
    },
    // 初始化连接
    initwebsocket() {
      this.websocket = new WebSocket(this.wsurl + '/ws')
      this.websocket.onmessage = this.websocketonmessage
      this.websocket.onerror = this.websocketonerror
    },
    // 绑定uid
    binduid() {
      const msg = {
        type: 'bind',
        userid: this.userid,
        orderid: this.orderinfo.id
      }
      this.websocketsend(JSON.stringify(msg))
    },
    // 连接错误
    websocketonerror() {
      this.initwebsocket()
    },
    // 接收到数据
    websocketonmessage(e) {
      const message = JSON.parse(e.data)
      switch (message.type) {
        case 'init':
          this.binduid()
          break
        case 'order':
          if (message.userid === this.userid) {
            this.list.push(message)
          }
          break
        case 'text':
          if (message.userid === this.touid) {
            this.list.push(message)
            this.$refs.notify.play()
            this.isread()
          }
          break
        case 'image':
          if (message.userid === this.touid) {
            this.list.push(message)
            this.$refs.notify.play()
            this.isread()
          }
          break
      }
      this.goto()
    },
    // websorcket发送信息
    websocketsend(data) {
      this.websocket.send(data)
    },
    // 发送信息
    sendmsg(type) {
      if (this.message === '') {
        return false
      }
      const msgdata = {
        type: type,
        userid: this.userid,
        touid: this.touid,
        content: this.message,
        orderid: this.orderinfo.id
      }
      // console.log(msgdata)
      this.websocketsend(JSON.stringify(msgdata))
      msgdata.content = msgdata.content.replaceAll('\n', '<br/>')
      this.list.push(msgdata)
      this.message = ''
      this.goto()
    },
    // 发送文本信息
    sendtext() {
      this.sendmsg('text')
    },
    // 图片上传
    async afterRead(file) {
      const param = new FormData()
      param.append('file', file.file)
      const { data } = await this.$http.post('/home/user/uploadimg', param)
      if (data) {
        if (data.code === 200) {
          this.message = data.data.url
          this.sendmsg('image')
        } else {
          this.$toast(data.msg)
        }
      }
    },
    // 图片预览
    // previewimg(url) {
    //   ImagePreview([url])
    // },
    // 更新消息为已读
    async isread() {
      await this.$http.get('/home/user/isread?id=' + this.$route.params.id)
    }
  },
  beforeDestroy() {
    clearInterval(this.status_timer)
  }
}
</script>
<style lang="less" scoped>
.header {
  box-shadow: none;
}
.maincontent {
  padding: 46px 0;
  display: block;
  overflow-y: auto;
  font-size: 0.93333rem;
  background: #f1f3f5;
  height: 100vh;
  // 订单信息
  .orderinfo {
    background: #fff;
    padding: 10px 0;
    position: fixed;
    width: 100%;
    z-index: 999;
    .info-top {
      font-size: 0.725rem;
      color: #999;
      padding: 0 20px;
      margin-bottom: 8px;
      .van-icon {
        color: #ccc;
        margin-right: 5px;
      }
      .van-count-down {
        display: inline-block;
        color: #999;
      }
    }
    .info-bottom {
      font-size: 1rem;
      color: #004ea3;
      padding: 0 18px;
    }
  }
  .chatlist {
    margin-top: 6rem;
  }
  //
  .chat-order {
    font-size: 0.8rem;
    margin: 1rem 0;
    .order-time {
      display: block;
      color: #ccc;
      line-height: 1.5rem;
      font-size: 13px;
    }
    .order-msg {
      text-align: center;
      display: inline-block;
      padding: 0.3rem 0.6rem;
      background: #ccc;
      color: #fff;
    }
  }
  // 聊天信息
  .chatinfo {
    display: flex;
    margin: 0.5rem;
    flex: 1;
    .chatcontent {
      border-style: solid;
      border-width: 1px;
      border-radius: 0.2rem;
      color: #333;
      font-size: 0.9rem;
      margin: 0.3rem 0.65rem;
      padding: 0.4rem 0.5rem;
      max-width: 75%;
      position: relative;
      text-align: left;
      word-break: break-all;
    }
  }
  .left {
    justify-content: flex-start;
    .chatcontent {
      background-color: #fff;
      border-color: #fff;
      &:before {
        content: '';
        display: inline-block;
        height: 0;
        width: 0;
        position: absolute;
        border-top: 0.3rem solid transparent;
        border-right: 0.5rem solid #fff;
        border-bottom: 0.5rem solid transparent;
        top: 1rem;
        left: -0.5rem;
      }
    }
  }
  .right {
    justify-content: flex-end;
    .chatcontent {
      background-color: #9eea6a;
      border-color: #9eea6a;
      &:before {
        content: '';
        display: inline-block;
        height: 0;
        width: 0;
        position: absolute;
        border-top: 0.3rem solid transparent;
        border-left: 0.5rem solid#9EEA6A;
        border-bottom: 0.5rem solid transparent;
        top: 1rem;
        right: -0.5rem;
      }
    }
  }
}
.sendbox {
  border-top: 1px solid #ebebeb;
  background: #f8f8f8;
  padding: 0.5rem;
  display: flex;
  align-items: flex-end;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  .van-cell {
    margin: 0;
    padding: 0;
    border-radius: 3px;
    /deep/.van-field__control {
      min-height: 2rem;
      display: inline-block;
      background: #fff;
      padding: 0.1rem 0.2rem;
      max-height: 5rem;
      overflow-y: scroll;
    }
  }
  .img-btn {
    font-size: 1.6rem;
    color: #999;
    padding: 3px 8px;
  }
  .send-btn {
    width: 5rem;
    padding: 0;
    height: 1.84rem;
  }
}
</style>
